import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  { path: "/", redirect: "/pickgoods" },
  {
    path: "/pickgoods",
    name: "pickgoods",
    component: (resolve) => require(["@/views/pick/pickgoods"], resolve),
    meta: { title: "自助提货",keepAlive: true},
  },
  {
    path: "/success",
    name: "success",
    component: (resolve) => require(["@/views/pick/success"], resolve),
    meta: { title: "支付成功" },
  },
  {
    path: "/network",
    name: "network",
    component: (resolve) => require(["@/views/pick/network"], resolve),
    meta: { title: "物流信息" },
  },
  {
    path: "/product",
    name: "Product",
    component: (resolve) => require(["@/views/pick/product"], resolve),
    meta: { title: "商品列表" ,keepAlive: false},
  }
];
const router = new VueRouter({
  base: process.env.BASE_URL,
  routes,
});

export default router;
