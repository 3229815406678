import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
// import VueCookies from 'vue-cookies'
//使用vant
import Vant from 'vant'
import { Area } from 'vant';
import { Toast } from 'vant';
import { Icon } from 'vant';
import { Dialog } from 'vant';
import { Cell, CellGroup } from 'vant';
import { Divider } from 'vant';
import { Step, Steps } from 'vant';
import "@/assets/css/common.css";

import 'vant/lib/index.css';
// import '@/permission'
Vue.use(Vant)
Vue.use(Area);
Vue.use(Toast);
Vue.use(Icon);
Vue.use(Dialog);
Vue.use(Cell);
Vue.use(CellGroup);
Vue.use(Divider);
Vue.use(Step);
Vue.use(Steps);
// Vue.use(VueCookies)
Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
